import React from "react";
import { Router } from "@reach/router";
import LoginPage from "modules/login/LoginPage";
import { UnauthenticatedTemplate } from "@azure/msal-react";

const Login: React.FC = () => {
  return (
    // <UnauthenticatedTemplate>
    <Router>
      <LoginPage path="/login" />
    </Router>
    // </UnauthenticatedTemplate>
  );
};

export default Login;

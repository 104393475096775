import { Link } from "gatsby";
import React, { useEffect } from "react";
import "assets/styles/styles.scss";
import "assets/styles/pages/login-page.scss";
import "assets/styles/components/button.scss";
import "assets/styles/components/input.scss";
import { RouteComponentProps } from "@reach/router";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "authConfig";

const GoogleIcon = require("assets/icons/Google.svg");
const FacebookIcon = require("assets/icons/FB.svg");

export interface User {
  email: string;
  password: string;
}

const LoginPage: React.FC<RouteComponentProps> = () => {
  // const [email, setEmail] = useState<string>("");
  // const [password, setPassword] = useState<string>("");
  // const [user, setUser] = useState<User>({ email: "", password: "" });
  // const submitRef = useRef<HTMLButtonElement>(null);
  // const formRef = useRef(null);
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const handleLogout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   setUser({
  //     email: email,
  //     password: password,
  //   });

  //   localStorage.setItem("user", JSON.stringify(user));

  //   setEmail("");
  //   setPassword("");

  //   console.log(user);

  //   return user;
  // };

  // useEffect(() => {
  //   if (!submitRef.current) return;

  //   if (email && password) {
  //     submitRef.current.disabled = false;
  //   } else {
  //     submitRef.current.disabled = true;
  //   }
  // }, [email, password, isAuthenticated, instance]);

  return (
    <article className="login">
      <header className="login__header">
        <p className="login__intro">
          {isAuthenticated ? "Hey you, hello again!" : "You better log in"}
          Hi again!
          <br />
          You can continue where you left off.
        </p>
        <footer>
          <small className="login__copyright">
            © Urban Sport and Culture Volunteers
          </small>
        </footer>
      </header>
      <article className="login__main">
        <div className="login__content">
          <p>
            Don’t have an account? <Link to="/register">Register here</Link>
          </p>
          <section>
            <h1 className="login__title">Log in</h1>
            <AuthenticatedTemplate>You are logged in.</AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              You are logged out
            </UnauthenticatedTemplate>
            {/* <form ref={formRef} onSubmit={handleSubmit} className="login__form">
              <div className="input__group" role="presentation">
                <label className="input__label" htmlFor="email">
                  Email Address
                </label>
                <input
                  className="input__field"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Type your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input__group" role="presentation">
                <label className="input__label" htmlFor="password">
                  Password
                </label>
                <input
                  className="input__field"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Type your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="login__buttons" role="presentation">
                <button
                  ref={submitRef}
                  type="submit"
                  className="btn btn--success btn--block"
                  disabled>
                  Log in
                </button>
                <Link
                  to="/forgot-password"
                  className="btn btn--ghost btn--block">
                  Forgot Password?
                </Link>
              </div>
            </form> */}
            {isAuthenticated ? (
              <button
                className="btn btn--ghost btn--block"
                onClick={handleLogout}>
                Log out using pop-up
              </button>
            ) : (
              <button
                className="btn btn--primary btn--block"
                onClick={handleLogin}>
                Log in using pop-up
              </button>
            )}
          </section>
          <footer className="login__footer">
            <p>Or you can Log in using</p>
            <div className="login__icons" role="presentation">
              <GoogleIcon />
              <FacebookIcon />
            </div>
          </footer>
        </div>
      </article>
    </article>
  );
};

export default LoginPage;
